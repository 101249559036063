<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Drivers</h1>
						</div>
						<div class="d-flex justify-content-end align-items-center">
							<router-link
								v-if="userHasPermission('drivers008')"
								:to="{ name: 'NotifyDrivers' }"
								class="btn btn-outline-primary mr-2"
								>Notify</router-link
								>
							<router-link to="/drivers/add" class="btn btn-primary" v-if="userHasPermission('drivers001')"
								>Add Driver</router-link
								>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header justify-content-between">
							<div
								class="input-group input-group-flush d-flex flex-row-reverse"
								>
								<input
									v-model.trim="search"
									class="form-control list-search"
									type="search"
									placeholder="Search"
									/>
								<span class="input-group-text border-0">
									<i class="fe fe-search"></i>
								</span>
							</div>
							<div class="col-auto" v-if="drivers.length">
								<v-datepicker
									style="width: 100%"
									v-model="filter.range"
									placeholder="Filter by date"
									range
									></v-datepicker>
							</div>
							<div v-if="selectedDrivers.length">
								<button
									type="submit"
									class="btn btn-primary mt-2"
									:disabled="processing || selectedDrivers.length === 0"
									@click="deactivateSelectedDrivers"
									>
									{{ processing ? 'Processing...' : 'Deactivate' }}
								</button>
							</div>
						</div>
						<div class="d-flex justify-content-between align-items-center">
							<div class="p-3">
								<label for="status_active" class="d-block-inline px-2">
									<input
										type="radio"
										v-model="statusFilter"
										name="status"
										value="active"
										id="status_active"
										/>
									Active
								</label>
								<label for="status_inactive" class="d-block-inline px-2">
									<input
										type="radio"
										v-model="statusFilter"
										name="status"
										value="inactive"
										id="status_inactive"
										/>
									Inactive
								</label>
							</div>
							<div class="">
								<download-button
									v-if="drivers.length"
									:downloading="downloadingReport"
									label="Download Report"
									@download="downloadReport()"
									></download-button>
							</div>
						</div>
						<b-table
							striped
							hover
							selectable
							responsive
							:items="drivers"
							:fields="fields"
							:current-page="currentPage"
							:busy="fetchingDrivers"
							@row-clicked="viewDriver"
							>
							<template #table-busy>
								<div class="text-center text-secondary my-2">
									<strong>Loading...</strong>
								</div>
							</template>

							<template #head(checkAll)>
								<input
									v-model="checkAllDrivers"
									type="checkbox"
									id="check-all"
									/>
							</template>
							<template #cell(checkAll)="data">
								<input
									:checked="
										selectedDrivers.find(
											(driver) => driver.id === data.item.id
										) || checkAllDrivers
									"
									@input="selectDriver(data.item)"
									type="checkbox"
									id="check-all"
									/>
							</template>
							<template #cell(driver)="data">
								<p class="font-weight-bold" style="line-height: 1">
									<router-link
										class="sh-page-link"
										:to="{
											name: 'ShowDriver',
											params: { driverId: data.item.id },
										}"
										>{{ `${data.item.fname} ${data.item.lname}` }}</router-link
										>
								</p>
								<p style="line-height: 1">
									<router-link
										class="sh-page-link"
										:to="{
											name: 'ShowDriver',
											params: { driverId: data.item.id },
										}"
										>{{ data.item.email }}</router-link
										>
								</p>
								<p style="line-height: 1">
									<router-link
										class="sh-page-link"
										:to="{
											name: 'ShowDriver',
											params: { driverId: data.item.id },
										}"
										>{{ data.item.phone }}</router-link
										>
								</p>
							</template>
							<template #cell(average_rating)="data">
								<average-rating
									:rating="data.item.average || 0"
									:total-trips="data.item.trip_count"
									></average-rating>
							</template>
							<template #cell(avatar)="data">
								<user-avatar :user="data.item" size="sm"></user-avatar>
							</template>

							<template #cell(created_at)="data">
								{{ data.value | date('dd/MM/yyyy') }}
							</template>

							<template #cell(status)="data">
								<span
									class="badge text-white"
									:class="data.item.active == 1 ? 'bg-success' : 'bg-danger'"
									>{{ data.item.active == 1 ? 'Active' : 'Inactive' }}</span
									>
							</template>
						</b-table>

						<div class="card-footer" v-if="totalRecords">
							<b-pagination
								v-model="currentPage"
								:total-rows="totalRecords"
								:per-page="perPage"
								></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import UserAvatar from '@/components/core/UserAvatar.vue'
import AverageRating from '@/components/core/AverageRating.vue'
import { ExportToCsv } from 'export-to-csv'
import moment from 'moment'
import { joinWithRatingsData } from '@/utils/helpers'
import DownloadButton from '@/components/core/DownloadButton.vue'
import _ from 'lodash'

export default {
  components: {
    UserAvatar,
    AverageRating,
    DownloadButton
  },
  data () {
    return {
      fields: [
        {
          key: 'checkAll',
          label: 'a'
        },
        {
          key: 'driver',
          label: 'User'
        },
        {
          key: 'average_rating',
          label: 'Average Rating'
        },
        {
          key: 'avatar',
          label: 'Avatar'
        },
        {
          key: 'created_at',
          label: 'Date Joined'
        },
        {
          key: 'status',
          label: 'Status'
        }
      ],
      perPage: 20,
      currentPage: 1,
      totalRecords: 0,
      drivers: [],
      fetchingDrivers: true,
      search: '',
      checkAllDrivers: false,
      selectedDrivers: [],
      processing: false,
      statusFilter: 'active',
      downloadingReport: false,
      settingsId: '',
      filter: {
        range: []
      }
    }
  },
  created () {
    this.initDrivers()
    this.debouncedCallback = _.debounce(() => {
      this.initDrivers(true)
    }, 1000)
  },
  computed: {
    filteredDrivers () {
      const search = this.search.toLowerCase()

      const drivers = this.drivers.filter((driver) => {
        return (
          driver.fname.toLowerCase().includes(search) ||
          driver.lname.toLowerCase().includes(search) ||
          driver.email.toLowerCase().includes(search) ||
          driver.phone.toLowerCase().includes(search)
        )
      })

      return drivers
    },

    dateRange () {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    },

    dateFilterValid () {
      return (
        this.filter.range.length &&
        this.filter.range.filter((date) => !!date).length > 0
      )
    }
  },
  watch: {
    checkAllDrivers (value) {
      if (value) {
        this.selectedDrivers = [...this.drivers]
      } else {
        this.selectedDrivers = []
      }
    },
    currentPage () {
      this.initDrivers()
    },
    search () {
      this.debouncedCallback()
    },
    statusFilter () {
      this.initDrivers(true)
    },
    dateFilterValid () {
      this.initDrivers(true)
    }
  },
  methods: {
    async fetchTripRatingSettings (serviceId) {
      try {
        const tripRatingSettingsResponse = await this.axios.get(
        `/rating/settings/service-id/${serviceId}`
        )
        return tripRatingSettingsResponse.data.data
      } catch (e) {
        return { reference: null }
      }
    },
    async initDrivers (reset = false) {
      if (reset) {
        this.currentPage = 1
      }

      this.fetchingDrivers = true

      const { reference } = await this.fetchTripRatingSettings(
        process.env.VUE_APP_TRIP_RATING_SERVICE_ID
      )
      this.settingsId = reference

      const ratingsPayload = {
        settings_id: this.settingsId,
        parameter: 'driver_id',
        start_date: '2022-01-01',
        end_date: `${moment(new Date()).format('YYYY-MM-DD')}`
      }

      Promise.all([
       this.axios.get(`/v1/drivers?page=${this.currentPage}&limit=${this.perPage}&search=${this.search}&metadata=true&status=${this.statusFilter}&start_date_filter=${this.dateRange[0]}&end_date_filter=${this.dateRange[1]}&related=device`),
        this.axios.post('/rating/reports/generic', ratingsPayload).catch((err) => Promise.resolve(null))
      ])
        .then((res) => {
          const drivers = res[0].data.data
          const driversRatings = res[1].data.data
          const mergedData = joinWithRatingsData(drivers, driversRatings)
          this.drivers = mergedData
          this.totalRecords = res[0].data?.metadata?.total
        })
        .catch(() => {})
        .finally(() => (this.fetchingDrivers = false))
    },
    selectDriver (selectedDriver) {
      const index = this.selectedDrivers.findIndex(
        (driver) => driver.id === selectedDriver.id
      )

      if (index !== -1) {
        this.selectedDrivers.splice(index, 1)
      } else {
        this.selectedDrivers.push(selectedDriver)
      }
    },
    async deactivateSelectedDrivers () {
      const result = await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to deactivate selected driver(s)?',
        showConfirmButton: true,
        showCancelButton: true
      })
      if (result.isConfirmed) {
        this.processing = true

        const payload = {
          ids: this.selectedDrivers.map((driver) => driver.id),
          active: 0
        }

        this.axios
          .patch('/v1/drivers/update-availability', payload)
          .then(() => {
            this.$swal({
              icon: 'success',
              title: 'Drivers deactivated',
              text: 'All selected drivers were successfully deactivated',
              showCloseButton: true
            })
          })
          .finally(() => {
            this.reset()
            this.initDrivers()
          })
      }
    },
    reset () {
      this.processing = false
      this.selectedDrivers = []
      this.checkAllDrivers = false
    },
    viewDriver (driver) {
      this.$router.push({ name: 'ShowDriver', params: { driverId: driver.id } })
    },

    // TODO : pass status filter as params when filtering based on date range.
    async downloadReport () {
      try {
        this.$Progress.start()
        this.downloadingReport = true
        const results =
          Array.isArray(this.dateRange) && !this.dateRange.length
            ? await this.axios.get(
                `/v1/drivers?page=${this.currentPage}&limit=${this.totalRecords}&search=${this.search}&metadata=true&include=trip_count&status=${this.statusFilter}`
            )
            : await this.axios.get(
                `/v1/drivers?metadata=true&include=trip_count&page=${this.currentPage}&limit=${this.totalRecords}&start_date_filter=${this.dateRange[0]}&end_date_filter=${this.dateRange[1]}`
            )
        const drivers = results.data.data
        this.$Progress.finish()
        const csvData = drivers.map((data) => {
          return {
            name: data.fname + ' ' + data.lname,
            tripCount: data.trip_count,
            date_joined: data.created_at,
            status: data.active == 1 ? 'Active' : 'Inactive'
          }
        })

        const csvParams = {
          fieldSeparator: ',',
          filename:
            Array.isArray(this.dateRange) && !this.dateRange.length
              ? 'Drivers List'
              : `Drivers List from ${this.dateRange[0]}  to ${this.dateRange[1]}`,
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title:
            Array.isArray(this.dateRange) && !this.dateRange.length
              ? 'Drivers List'
              : `Drivers List from ${this.dateRange[0]}  to ${this.dateRange[1]}`,
          useTextFile: false,
          useBom: true,
          headers: ['Name', 'Trip Count', 'Date Joined', 'Status']
        }

        const csvExporter = new ExportToCsv(csvParams)

        csvExporter.generateCsv(csvData)

        this.$swal({
          icon: 'success',
          title: 'Report downloaded',
          text: 'Report has been downloaded successfully',
          showCloseButton: true
        })
      } catch (error) {
        this.$Progress.fail()
      } finally {
        this.downloadingReport = false
      }
    }
  },
  beforeDestroy () {
    this.debouncedCallback.cancel()
  }
}
</script>
